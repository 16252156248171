var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.GET_IS_CORE_CONTENT_READY)?_c('div',{staticClass:"bleach-site active-banner",class:{
    'show-banner': _vm.showBanner && !_vm.disableBanner,
    'cart-alert-active': _vm.alertActive
  }},[_c('Navigation'),(_vm.getBanner)?_c('Banner',{attrs:{"content":_vm.getBanner}}):_vm._e(),_c('AuthPushdown'),_c('div',{staticClass:"bleach-site__store",attrs:{"id":"scroll-lock-content"}},[_c('keep-alive',{attrs:{"include":[
        'CollectionPage',
        'GenericProductListing',
        'Page',
        'ProductPage'
      ],"max":3}},[_c('router-view')],1)],1),(_vm.getShouldHideLoader)?_c('Footer',{attrs:{"content":_vm.GET_SITE_CONFIG.footer}}):_vm._e(),_c('vue-accessible-modal'),_c('modal-container')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }